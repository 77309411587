import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinus, faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import { FaStar } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import VariationSlider from "../Components/VariationSlider/VariationSlider";
import { Helmet } from "react-helmet-async";
import { ThreeDots } from "react-loader-spinner";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { ReactPixel } from "react-facebook-pixel";
import { ThemeContext } from "../Contexts/ThemeContext";
import { addToDb } from "../utilities/CartDb";
import StarRating from "../Components/Ratings";

const ComboProductDetails = () => {
  // const { products } = useContext(ThemeContext);
  const slug = "winter-glow";
  const [quantity, setQuantity] = useState(1);
  const { cart, setCart } = useContext(ThemeContext);
  const [selectedTab, setSelectedTab] = useState(1);
  const [selectedRating, setSelectedRating] = useState(0);
  const [reviewText, setReviewText] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [reviews, setReviews] = useState([]);
  const [image, setImage] = useState("");
  const [product, setProduct] = useState({});
  const [loading, setLoading] = useState(true);
  const [tags, setTags] = useState([]);
  const [previewImage, setPreviewImage] = useState("");
  const [reviewImage, setReviewImage] = useState("");
  const [fade, setFade] = useState(false);
  const isMobile = window.innerWidth < 992;
  const ImageGallery = product?.images?.filter((img) => img.src);
  const [isFaqOpen, setIsFaqOpen] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const navigate = useNavigate();
  const [translate, setTranslate] = useState(true);
  const [selectedOptions, setSelectedOptions] = useState({
    facewash: "Face wash",
    moisturizer: "Moisturizer",
    bodyMilk: "Moisturizing Vitamin E",
    oil: "Oil",
  });

  const facewashOptions = ["Aloevera", "Lemon", "Milk", "Neem"];
  const moisturizerOptions = ["Normal & Dry Skin", "Combination & Oily Skin"];
  const bodyMilkOptions = ["Moisturizing Vitamin E"];
  const oilOptions = ["Argan", "Onion", "Coconut"];

  // Handler for option semoisturizerlection
  const handleSelection = (category, option) => {
    setSelectedOptions((prevState) => ({
      ...prevState,
      [category]: option,
    }));
  };

  const handleFaqClick = (index) => {
    setIsFaqOpen(index === isFaqOpen ? false : index);
  };

  // useEffect(()=>{
  //   if(selectedOptions.facewash==="Aloe"){
  //     setImage("https://luvit.com.bd/wp-content/uploads/2024/12/Aloe-Vera_Face-Wash____Transprant_front.png")
  //   }
  //   if(selectedOptions.facewash==="Lemon"){
  //     setImage("https://luvit.com.bd/wp-content/uploads/2024/12/Lemon_Face-Wash____Transprant_Front.png")
  //   }
  //   if(selectedOptions.facewash==="Milk"){
  //     setImage("https://luvit.com.bd/wp-content/uploads/2024/12/Milk_Face-Wash____Transprant_Front.png")
  //   }
  //   if(selectedOptions.facewash==="Neem"){
  //     setImage("https://luvit.com.bd/wp-content/uploads/2024/12/Neem_Face-Wash____Transprant_Front.png")
  //   }
  //   if(selectedOptions.moisturizer==="Normal & Dry"){
  //     setImage("https://luvit.com.bd/wp-content/uploads/2024/12/Moisturizer-Normal-Dry-Skin.png")
  //   }
  //   if(selectedOptions.moisturizer==="Combination & Oily"){
  //     setImage("https://luvit.com.bd/wp-content/uploads/2024/12/Moisturizer-Combination-Oily-Skin.png")
  //   }
  //   if(selectedOptions.moisturizer==="Combination & Oily"){
  //     setImage("https://luvit.com.bd/wp-content/uploads/2024/12/Moisturizer-Combination-Oily-Skin.png")
  //   }
  // },[selectedOptions])

  useEffect(() => {
    fetch(`https://ebay-backend.vercel.app/getSingleProduct/winter-glow`)
      .then((res) => res.json())
      .then((data) => {
        setProduct(data);
        setLoading(false);
        // data.tags.map((tag) => !tags.includes(tag.name) && tags.push(tag.name));
      });
  }, []);
  console.log(selectedOptions);
  const handlePurchase = (item) => {
    if (
      selectedOptions.facewash === "Face wash" ||
      selectedOptions.moisturizer === "Moisturizer" ||
      selectedOptions.oil === "Oil"
    ) {
      toast.error("Please select atleast 1 item from each category");
    } else {
      let newCart = [];
      item.quantity = quantity;
      newCart = [item];
      let shoppingCart = {};
      shoppingCart[product?._id] = quantity;
      if (item.name === "Winter Glow") {
        const updatedName = `Winter Glow (${[
          selectedOptions.facewash,
          selectedOptions.moisturizer,
          selectedOptions.bodyMilk,
          selectedOptions.oil,
        ]
          .filter((item) => item) // Remove empty selections
          .join(" / ")})`;

        localStorage.setItem(
          "combo",
          JSON.stringify({ name: updatedName, id: item._id })
        );
      }
      setCart(newCart);
      localStorage.setItem("shopping-cart", JSON.stringify(shoppingCart));
      navigate("/combo/cart");
    }
  };

  const totalRating = reviews.reduce((sum, review) => sum + review.rating, 0);
  const avgRating = (totalRating / reviews.length).toFixed(1);

  const renderStar = (starNumber) => {
    const starIcon =
      starNumber <= selectedRating ? (
        <FaStar size={18} color="#FFD700" />
      ) : (
        <AiOutlineStar size={18} color="#FFD700" />
      );

    return (
      <button
        onClick={() => setSelectedRating(starNumber)}
        key={starNumber}
        style={{ marginTop: 6 }}
      >
        {starIcon}
      </button>
    );
  };

  function stripHTMLTags(html) {
    const tmpElement = document.createElement("div");
    tmpElement.innerHTML = html;
    return tmpElement.textContent || tmpElement.innerText || "";
  }
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-[50vh]">
          <ThreeDots
            height="100"
            width="100"
            radius="10"
            color="#7dc569"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      ) : (
        <div className="2xl:w-[85%] lg:w-[90%] mx-auto lg:mt-10 relative">
          <Helmet>
            <title>{`${product?.name} - Earth Beauty & You`}</title>
            <meta
              name="description"
              content={
                product?.meta_description
                  ? product?.meta_description
                  : stripHTMLTags(product?.short_description).slice(0, 150) +
                    "...."
              }
            />
          </Helmet>

          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col lg:flex-row-reverse w-[100%] lg:w-[70%] lg:m-10 gap-4">
              <div className="w-[100%] lg:w-[85%]">
                <img
                  className={`w-[100%] ease-in-out duration-500 ${
                    fade ? "opacity-0" : "opacity-1"
                  }`}
                  src={image || product?.images[0]?.src}
                  alt=""
                />
              </div>
              <div className="flex mx-auto lg:flex-col gap-6 my-0 w-[90vw] lg:w-[18%] lg:h-[550px] 2xl:h-[750px] overflow-hidden">
                <Swiper
                  direction={
                    window.innerWidth < 992 ? "horizontal" : "vertical"
                  }
                  slidesPerView={window.innerWidth < 992 ? 3 : 4}
                  spaceBetween={10}
                  navigation={{ disabled: true }}
                  autoplay={{
                    delay: 1000,
                    disableOnInteraction: false,
                  }}
                  pagination={{ clickable: true }}
                  modules={[Autoplay]}
                  className="lg:h-[550px] 2xl:h-[750px] w-[90%] lg:w-[100%] "
                >
                  {ImageGallery?.map((img, index) => (
                    <SwiperSlide key={index}>
                      <LazyLoadImage
                        alt=""
                        src={img.src}
                        effect="blur"
                        onClick={() => {
                          setFade(true);
                          setTimeout(() => setFade(false), 500);
                          setTimeout(() => setImage(img.src), 500);
                        }}
                        className={`w-full border border-2 p-1 cursor-pointer border-secondary ${
                          img.src ? "" : "hidden"
                        }`}
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>

            <div className="w-[100%] lg:w-[50%] mx-auto px-6 lg:px-0 py-10">
              <p className="text-3xl my-3">{product?.name}</p>
              {product.name.toLowerCase() === "winter glow" ? (
                ""
              ) : (
                <div className="flex items-center">
                  <StarRating rating={avgRating} size={18}></StarRating>
                  {/* <FaStar size={18} color="#FAB73B"/> */}
                  <p className="mx-2" style={{ color: "#000" }}>
                    ({avgRating > 0 ? avgRating : 0}) | {reviews.length}{" "}
                    Customer Reviews
                  </p>
                </div>
              )}

              <p className="text-sm text-black text-opacity-70">
                {product?.brand?.toUpperCase()}
              </p>
              <br />
              {product.name.toLowerCase() === "winter glow" && (
                <div className="grid grid-cols-2 gap-6">
                  {/* Facewash Selection */}
                  <div>
                    <h4 className="mb-4 sm:text-2xl">Facewash</h4>
                    {facewashOptions.map((option) => (
                      <label
                        key={option}
                        style={{ display: "block", marginBottom: "5px" }}
                      >
                        <input
                          type="radio"
                          name="facewash"
                          required
                          value={option}
                          checked={selectedOptions.facewash === option}
                          onChange={() => handleSelection("facewash", option)}
                          style={{ marginRight: "10px" }}
                        />
                        {option}
                      </label>
                    ))}
                  </div>

                  {/* Oil Selection */}
                  <div>
                    <h4 className="mb-4 sm:text-2xl">Hair Oil</h4>
                    {oilOptions.map((option) => (
                      <label
                        key={option}
                        style={{ display: "block", marginBottom: "5px" }}
                      >
                        <input
                          type="radio"
                          name="oil"
                          required
                          value={option}
                          checked={selectedOptions.oil === option}
                          onChange={() => handleSelection("oil", option)}
                          style={{ marginRight: "10px" }}
                        />
                        {option}
                      </label>
                    ))}
                  </div>

                  {/* Moisturizer Selection */}
                  <div>
                    <h4 className="mb-4 sm:text-2xl">Moisturizer</h4>
                    {moisturizerOptions.map((option) => (
                      <label
                        key={option}
                        style={{ display: "block", marginBottom: "5px" }}
                      >
                        <input
                          type="radio"
                          name="moisturizer"
                          required
                          value={option}
                          checked={selectedOptions.moisturizer === option}
                          onChange={() =>
                            handleSelection("moisturizer", option)
                          }
                          style={{ marginRight: "10px" }}
                        />
                        {option}
                      </label>
                    ))}
                  </div>

                  {/* Body Milk Selection */}
                  <div>
                    <h4 className="mb-4 sm:text-2xl">Body Lotion</h4>
                    {bodyMilkOptions.map((option) => (
                      <label
                        key={option}
                        style={{ display: "block", marginBottom: "5px" }}
                      >
                        <input
                          type="radio"
                          name="bodyMilk"
                          value={option}
                          checked={selectedOptions.bodyMilk === option}
                          onChange={() => handleSelection("bodyMilk", option)}
                          style={{ marginRight: "10px" }}
                        />
                        {option}
                      </label>
                    ))}
                  </div>
                </div>
              )}
              <div className={`flex gap-2 mb-4 mt-10`}>
                <p className="text-accent font-bold text-3xl">
                  BDT{" "}
                  {JSON.stringify(product?.on_sale) === "true"
                    ? product?.sale_price
                    : product?.regular_price}{" "}
                </p>
                {JSON.stringify(product?.on_sale) === "true" && (
                  <p
                    style={{
                      textDecorationLine: "line-through",
                      textDecorationStyle: "solid",
                      color: "#000",
                      opacity: 0.5,
                      fontSize: "20px",
                    }}
                  >
                    {`${product?.regular_price} BDT`}
                  </p>
                )}
              </div>
              <div
                className="text-black text-opacity-70 mb-4"
                dangerouslySetInnerHTML={{ __html: product?.short_description }}
              />
              {/* ......................attributes............................ */}
              <div style={{ flex: 1, marginBottom: 20 }}>
                {product?.attributes?.map((attribute) => (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p style={{ fontSize: 16 }}>{attribute?.name}</p>
                      <p style={{ color: "#000", opacity: 0.5 }}>
                        {attribute?.options}
                      </p>
                    </div>
                    <div
                      style={{
                        borderBottomColor: "black",
                        borderBottomWidth: 1,
                        borderStyle: "dotted",
                        marginTop: 6,
                        opacity: 0.2,
                      }}
                    ></div>
                  </div>
                ))}
              </div>

              <p>Quantity : </p>
              <div className="flex flex-col sm:flex-row sm:items-center gap-4 ">
                <div className="flex items-center">
                  <FontAwesomeIcon
                    onClick={() => quantity > 1 && setQuantity(quantity - 1)}
                    className="text-black hover:text-primary hover:bg-accent cursor-pointer text-xs p-[10px] h-[18px] border border-secondary rounded"
                    icon={faMinus}
                  ></FontAwesomeIcon>
                  <input
                    type="text"
                    Value={quantity}
                    disabled
                    className="border-secondary border w-[60px] my-[10px] text-center h-[40px] border-x-0"
                  />
                  <FontAwesomeIcon
                    onClick={() => setQuantity(quantity + 1)}
                    className="text-black bg-primary hover:text-primary hover:bg-accent cursor-pointer text-xs p-[10px] h-[18px] border border-secondary rounded"
                    icon={faPlus}
                  ></FontAwesomeIcon>
                </div>
                <div className="flex gap-4">
                  {/* <button
                    onClick={() => handleAddToCart(product)}
                    className={`btn hover:bg-secondary text-primary w-[45%] sm:w-[60%] border-none font-bold rounded ${
                      product?.stock_quantity < 1
                        ? "bg-[#cccccc] pointer-events-none"
                        : "bg-accent"
                    }`}
                  >
                    Add to cart
                  </button> */}
                  <button
                    // to={quantity < 2 ? "/shipping" : "/cart"}
                    onClick={() => handlePurchase(product)}
                    className={`btn hover:bg-black text-primary w-[200px] sm:w-[200px] border-none font-bold rounded ${
                      product?.stock_quantity < 1
                        ? "bg-[#cccccc] pointer-events-none"
                        : "bg-[#DF1B4D]"
                    }`}
                  >
                    Buy now
                  </button>
                </div>
              </div>
              <p className="my-2 font-bold">
                Subtotal:{" "}
                {(JSON.stringify(product?.on_sale) === "true"
                  ? product?.sale_price
                  : product?.regular_price) * quantity}{" "}
                BDT
              </p>
              {/* ...........variations............... */}
              {product?.variations?.length > 1 && (
                <div className="flex gap-4 mb-3 h-[40px]">
                  <VariationSlider
                    variations={product?.variations}
                    id={product._id}
                  ></VariationSlider>
                </div>
              )}
              {product?.tags?.length > 0 && (
                <div className="font-bold mt-16">
                  Tags :
                  {product.tags.map((tag) => (
                    <span className="font-bold mx-1">{tag?.name} |</span>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComboProductDetails;
