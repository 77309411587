import React, { Suspense, useState } from "react";
import { Helmet } from "react-helmet-async";
import icon from "../../Images/ogIcon.png";
import "./Home.css";
import Banner from "../../Components/Banner/Banner";
import HomeNewArrivals from "../../Components/HomeNewArrivals/HomeNewArrivals";
import ShopByConcern from "../../Components/ShopByConcern/ShopByConcern";
import MostFavorite from "../../Components/MostFavorite/MostFavorite";
import ShopByCategory from "../../Components/ShopByCategory/ShopByCategory";
import SwingImage from "../../Components/SwingImage/SwingImage";
import Snowfall from "react-snowfall";

// Lazy-loaded components
const HomeAbout = React.lazy(() => import("../../Components/HomeAbout"));
const Featured = React.lazy(() => import("../../Components/Featured/Featured"));
const SkinTips = React.lazy(() => import("../../Components/SkinTips"));
const InstaShop = React.lazy(() => import("../../Components/InstaShop"));
const Policy = React.lazy(() => import("../../Components/Policy/Policy"));

const Home = () => {
  return (
    <div className="home">
      <Helmet>
        <title>Natural Skin and hair care Solution | Earth Beauty & You</title>
        <meta
          name="description"
          content="Shop eco-friendly and organic beauty products at Earth Beauty & You. Enhance your skincare routine with natural ingredients."
        />
        <link rel="canonical" href="https://earthbeautyandyou.com" />
        <meta property="og:title" content="Earth Beauty & You" />
        <meta
          property="og:description"
          content="Welcome to Earth Beauty & You – where nature meets beauty. Discover our curated collection of organic and natural products designed for your well-being. From skin care to hair care essentials, shop consciously and live beautifully. Embrace the beauty of nature with Earth Beauty & You."
        />
        <meta property="og:image" content={icon} />
        <meta property="og:url" content="https://earthbeautyandyou.com" />
        <meta property="og:type" content="website" />
        {/* JSON-LD Structured Data for SEO */}
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "Organization",
              "name": "Earth Beauty & You",
              "url": "https://earthbeautyandyou.com",
              "logo": "https://luvit.com.bd/wp-content/uploads/2024/10/download.png",
              "description": "Shop eco-friendly and organic beauty products at Earth Beauty & You. Enhance your skincare routine with natural ingredients.",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "16192 Coastal Highway",
                "addressLocality": "Lewes",
                "addressRegion": "Delaware",
                "postalCode": "19958",
                "addressCountry": "US"
              }
            }
        `}
        </script>
      </Helmet>
      <Snowfall style={{height:"600vh"}} snowflakeCount={500}/>     
      <Banner />
      {/* <div className="w-[200px] h-[40px] bg-black mx-auto text-center text-[#ffffff] font-bold mt-10 pt-2">BEST DEAL</div> */}
      {/* <SwingImage /> */}

      <HomeNewArrivals />
      <ShopByConcern />
      <MostFavorite />
      {/* <ShopByCategory /> */}

      <Suspense fallback={<div>Loading ...</div>}>
        <HomeAbout />
        <Featured />
        <SkinTips />
        <InstaShop />
        <Policy />
      </Suspense>
    </div>
  );
};

export default Home;
