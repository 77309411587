import Product from "../Shared/Product";
import { ThemeContext } from "../../Contexts/ThemeContext";
import { useContext } from "react";
import "./MostFavorite.css";
import { Link } from "react-router-dom";
import Snowfall from "react-snowfall";
const MostFavorite = () => {
  const { favProducts } = useContext(ThemeContext);
  const products = favProducts?.slice(0, 4);

  return (
    <div className="overflow-hidden">
      <div
        className={`flex flex-col px-[10px] items-center sm:px-[20px] lg:px-[0px] lg:w-[90%] mx-auto ${
          window.innerWidth >= 1920 ? "2xl:w-[65%]" : "2xl:w-[80%]"
        }`}
      >
        <h2 className="text-center text-2xl font-bold text-accent mt-16">
          Most Loved Skincare Beauty Products
        </h2>
        <div className="w-[130px] mx-auto h-1 bg-accent my-6 mb-10"></div>
        <div className="grid grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 sm:gap-6 featured-collections ">
          {products?.map((product) => (
            <Product key={product?._id} product={product}></Product>
          ))}
        </div>
        <Link
          to="/product-category/face wash"
          className="text-white bg-accent px-10 rounded sm:mt-20 py-2 hover:bg-secondary ease-in-out duration-200 font-bold sm:my-10 "
        >
          View More
        </Link>
      </div>
    </div>
  );
};

export default MostFavorite;
