import { createBrowserRouter } from "react-router-dom";
import Main from "../Layout/Main";
import Home from "../Pages/Home/Home";
import Login from "../Pages/Login/Login";
import Signup from "../Pages/Signup/Signup";
import CategoryPage from "../Pages/CategoryPage/CategoryPage";
import CartPage from "../Pages/CartPage/CartPage";
import Shipping from "../Pages/ShippingPage/Shipping";
import PrivateRoute from "../PrivateRoute/PrivateRoute";
import ProductDetails from "../Pages/ProductDetails/ProductDetails";
import Modal from "../Components/Modal/Modal";
import SearchedProductPage from "../Pages/SearchedProductPage";
import AdminDashboardLayout from "../Layout/AdminDashboardLayout";
import AllProducts from "../Pages/AdminDashboard/AllProducts";
import AddProduct from "../Pages/AdminDashboard/AddProduct";
import EditProduct from "../Pages/AdminDashboard/EditProduct";
import OrderList from "../Pages/AdminDashboard/OrderList";
import EditOrder from "../Pages/AdminDashboard/EditOrder";
import CustomerDashboard from "../Pages/CustomerDashboard/CustomerDashboard";
import OrderConfirmation from "../Pages/OrderConfirmation/OrderConfirmation";
import AllUser from "../Pages/AdminDashboard/AllUser";
import AdminRoute from "./AdminRoutes";
import CustomerOrders from "../Pages/AdminDashboard/CustomerOrders";
import Analytics from "../Pages/AdminDashboard/Analytics";
import OrderDetails from "../Pages/OrderDetails/OrderDetails";
import CouponList from "../Pages/AdminDashboard/CouponList";
import AddCoupon from "../Pages/AdminDashboard/AddCoupon";
import EditCoupon from "../Pages/AdminDashboard/EditCoupon";
import NewArrivals from "../Pages/NewArrivals/NewArrivals";
import Blogs from "../Pages/Blogs/Blogs";
import Blog from "../Pages/Blog/Blog";
import ReviewList from "../Pages/AdminDashboard/ReviewList";
import Offer40Percent from "../Pages/Offer40Percent";
import Offer25Percent from "../Pages/Offer25Percent";
import Mascara from "../Pages/Mascara";
import KissMeMore from "../Pages/KissMeMore";
import NotFound from "../Pages/NotFound";
import Campaign from "../Pages/Campaign";
import Malls from "../Pages/Malls";
import TrackOrder from "../Pages/TrackOrder/TrackOrder";
import Refund from "../Pages/Refund";
import ShippingPolicy from "../Pages/ShippingPolicy";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import Terms from "../Pages/Terms";
import About from "../Pages/About";
import Filter from "../Pages/AdminDashboard/Filter";
import Shop from "../Pages/Shop";
import NailEnamel from "../Pages/NailEnamel";
import Combo from "../Pages/ComboOffer/Combo";
import Combo1 from "../Pages/SingleComboPage/Combo1";
import ComboShipping from "../Pages/ShippingPage/comboShipping";
import Combo2 from "../Pages/SingleComboPage/Combo2";
import Combo3 from "../Pages/SingleComboPage/Combo3";
import Offer30Percent from "../Pages/offer30Percent";
import Bogo1 from "../Pages/Bogo1";
import Bogo2 from "../Pages/Bogo2";
import Bogo3 from "../Pages/Bogo3";
import MaxiBrush from "../Pages/MaxiBrush";
import AllBlog from "../Pages/AdminDashboard/AllBlog";
import EditBlog from "../Pages/AdminDashboard/EditBlog";
import AddBlog from "../Pages/AdminDashboard/AddBlog";
import Sitemap from "../Pages/Sitemap";
import ProductsByTag from "../Pages/ProductsByTag/ProductsByTag";
import OrderPage from "../Pages/ShippingPage/OrderPage";
import TestProductDetails from "../Pages/TestProductDetails";
import ComboProductDetails from "../Pages/combo";
import ComboCart from "../Pages/ComboCart";
import ComboCheckout from "../Pages/ComboCheckout";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Main></Main>,
    children: [
      {
        path: "/",
        element: <Home></Home>,
      },
      {
        path: "/newarrivals",
        element: <NewArrivals></NewArrivals>,
      },
      {
        path: "/offer40percent",
        element: <Offer40Percent></Offer40Percent>,
      },
      {
        path: "/offer30percent",
        element: <Offer30Percent></Offer30Percent>,
      },
      {
        path: "/offer25percent",
        element: <Offer25Percent></Offer25Percent>,
      },
      {
        path: "/mascara",
        element: <Mascara></Mascara>,
      },
      {
        path: "/kissmemore",
        element: <KissMeMore></KissMeMore>,
      },
      {
        path: "/full color nail enamel",
        element: <NailEnamel></NailEnamel>,
      },
      {
        path: "/maxi brush nail enamel",
        element: <MaxiBrush></MaxiBrush>,
      },
      {
        path: "/combo",
        element: <ComboProductDetails></ComboProductDetails>,
      },
      {
        path: "/combo/cart",
        element: <ComboCart></ComboCart>,
      },
      {
        path: "/combo/checkout",
        element: <ComboCheckout></ComboCheckout>,
      },
      {
        path: "/bogo1",
        element: <Bogo1></Bogo1>,
      },
      {
        path: "/bogo2",
        element: <Bogo2></Bogo2>,
      },
      {
        path: "/bogo3",
        element: <Bogo3></Bogo3>,
      },
      {
        path: "/blogs",
        element: <Blogs></Blogs>,
      },
      {
        path: "/shop",
        element: <Shop></Shop>,
      },
      // {
      //   path: "/combo",
      //   element: <Combo></Combo>,
      // },
      {
        path: "/combo/Combo1",
        element: <Combo1></Combo1>,
      },
      {
        path: "/combo/Combo2",
        element: <Combo2></Combo2>,
      },
      {
        path: "/combo/Combo3",
        element: <Combo3></Combo3>,
      },
      {
        path: "/trackOrder",
        element: <PrivateRoute> <TrackOrder></TrackOrder> </PrivateRoute>,
      },
      {
        path: "/refund",
        element: <Refund></Refund>,
      },
      {
        path: "/privacyPolicy",
        element: <PrivacyPolicy></PrivacyPolicy>,
      },
      {
        path: "/shippingPolicy",
        element: <ShippingPolicy></ShippingPolicy>,
      },
      {
        path: "/terms",
        element: <Terms></Terms>,
      },
      {
        path: "/about",
        element: <About></About>,
      },
      {
        path: "/sitemap",
        element: <Sitemap></Sitemap>,
      },
      {
        path: "/blog/:id",
        element: <Blog></Blog>,
      },
      {
        path: "/product-category/:category",
        element: <CategoryPage></CategoryPage>,
      },
      {
        path: "/search/:searchText",
        element: <SearchedProductPage></SearchedProductPage>,
      },
      {
        path: "/login",
        element: <Login></Login>,
      },
      {
        path: "/campaign",
        element: <Campaign></Campaign>,
      },
      {
        path: "/malls",
        element: <Malls></Malls>,
      },
      {
        path: "/*",
        element: <NotFound></NotFound>,
      },
      // {
      //     path: '/signup',
      //     element:<Signup></Signup>
      // },
      {
        path: "/customerDashboard",
        element: (
          <PrivateRoute>
            <CustomerDashboard></CustomerDashboard>
          </PrivateRoute>
        ),
      },
      {
        path: "/dashboard",
        element: (
            <CustomerDashboard></CustomerDashboard>
        ),
      },
      {
        path: "/signup",
        element: <Signup></Signup>,
      },
      {
        path: "/cart",
        element: <CartPage></CartPage>,
      },
      {
        path: "/shipping",
        element: <Shipping></Shipping>,
      },
      {
        path: "/shipping/orderpage",
        element: <OrderPage></OrderPage>,
      },
      {
        path: "/check-out",
        element: <ComboShipping></ComboShipping>,
      },
      {
        path: "/product/:slug",
        element: <ProductDetails />,
      },
      {
        path: "/sku/:sku",
        element: <ProductsByTag />,
      },
      {
        path: "/modal/:modalId",
        element: <Modal />,
      },
      {
        path: "/shipping/confirmOrder",
        element: <OrderConfirmation />,
      },
      {
        path: "/customerDashboard/:id",
        element: <OrderDetails />,
      },
    ],
  },
  {
    path: "/admin",
    // element: <PrivateRoute> <AdminDashboardLayout></AdminDashboardLayout> </PrivateRoute> ,
    element:  <AdminDashboardLayout></AdminDashboardLayout>,
    children: [
      {
        path: "/admin/seo/products",
        element:<AllProducts></AllProducts>,
      },
      {
        path: "/admin",
        element:<AdminRoute> <AllProducts></AllProducts> </AdminRoute>,
        // element:<AllProducts></AllProducts>,
      },
      {
        path: "/admin/addProduct",
        element:<AdminRoute> <AddProduct></AddProduct> </AdminRoute>,
        // element:<AddProduct></AddProduct>,
      },
      {
        path: "/admin/editProduct/:id",
        element:  <EditProduct></EditProduct>,
        // element: <AdminRoute> <EditProduct></EditProduct> </AdminRoute> ,
      },
      {
        path: "/admin/order/:id",
        element: <AdminRoute><EditOrder></EditOrder></AdminRoute> ,
        // element: <EditOrder></EditOrder>,
      },
      {
        path: "/admin/order",
        element: <AdminRoute><OrderList></OrderList></AdminRoute>,
        // element: <OrderList></OrderList>,
      },
      {
        path: "/admin/user",
        element: <AdminRoute><AllUser></AllUser></AdminRoute> ,
        // element: <AllUser></AllUser>,
      },
      {
        path: "/admin/user/:ph",
        element: <AdminRoute><CustomerOrders></CustomerOrders></AdminRoute>,
        // element: <CustomerOrders></CustomerOrders>,
      },
      {
        path: "/admin/coupons",
        element: <AdminRoute><CouponList></CouponList></AdminRoute>,
        // element: <CouponList></CouponList>,
      },
      {
        path: "/admin/addCoupon",
        element: <AdminRoute><AddCoupon></AddCoupon></AdminRoute>,
        // element: <AddCoupon></AddCoupon>,
      },
      {
        path: "/admin/editCoupon/:id",
        element: <AdminRoute><EditCoupon></EditCoupon></AdminRoute>,
        // element: <EditCoupon></EditCoupon>,
      },
      {
        path: "/admin/analytics",
        element: <AdminRoute><Analytics></Analytics></AdminRoute> ,
        // element: <Analytics></Analytics> ,
      },
      {
        path: "/admin/reviews",
        element: <AdminRoute><ReviewList></ReviewList></AdminRoute>,
        // element: <ReviewList></ReviewList>,
      },
      {
        path: "/admin/filter",
        element: <AdminRoute><Filter></Filter></AdminRoute> ,
        // element: <Filter></Filter>,
      },
      {
        path: "/admin/Allblog",
        element: <AdminRoute><AllBlog></AllBlog></AdminRoute> ,
        // element: <AllBlog></AllBlog>,
      },
      {
        path: "/admin/editBlog/:id",
        element: <AdminRoute><EditBlog></EditBlog></AdminRoute> ,
        // element: <EditBlog></EditBlog>,
      },
      {
        path: "/admin/AddBlog",
        element: <AdminRoute><AddBlog></AddBlog></AdminRoute> ,
        // element: <AddBlog></AddBlog>,
      },
    ],
  },
]);

export default router;
