import React from "react";
import "./Banner.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import lgBanner1 from "../../Images/Vitamin-C-pc-copy.jpg";
import lgBanner2 from "../../Images/facewash web slider pc.png";
import lgBanner4 from "../../Images/ebay web slider pc.png";
import lgBanner6 from "../../Images/Winter slider desktop view.png";
import lgBanner7 from "../../Images/Rose-Jam-pc-copy.jpg";
import lgBanner8 from "../../Images/Ebay new year web cover pc.png";
import mbBanner1 from "../../Images/Vitamin-C-Mob-copy.jpg";
import mbBanner2 from "../../Images/facewash  web slider modile.png";
import mbBanner4 from "../../Images/ebay web slider model 4-9.psd 1.png";
import mbBanner6 from "../../Images/Winter slider mobile view.png";
import mbBanner7 from "../../Images/Rose-Jam-mobile-copy.jpg";
import mbBanner8 from "../../Images/Ebay new year web cover mobile.png";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import timezone from 'dayjs/plugin/timezone'; 
import utc from 'dayjs/plugin/utc'; 

dayjs.extend(utc);
dayjs.extend(timezone);

const Banner = () => {
  const isDesktop = window.innerWidth > 992;
  const banner01 = isDesktop ? lgBanner1 : mbBanner1;
  const banner02 = isDesktop ? lgBanner2 : mbBanner2;
  const banner04 = isDesktop ? lgBanner4 : mbBanner4;
  const banner06 = isDesktop ? lgBanner6 : mbBanner6;
  const banner07 = isDesktop ? lgBanner7 : mbBanner7;
  const banner08 = isDesktop ? lgBanner8 : mbBanner8;


  const currentDate = dayjs().tz("Asia/Dhaka");

  const offerStartTime = dayjs.tz("2024-12-31 23:59:00", "Asia/Dhaka");
  const offerEndTime = dayjs.tz("2025-01-10 23:59:00", "Asia/Dhaka");

  const offerValidity =
    currentDate.isAfter(offerStartTime) && currentDate.isBefore(offerEndTime);

  return (
    <div>
      <Swiper
        spaceBetween={30}
        effect="fade"
        navigation={isDesktop}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
        }}
        pagination={window.innerWidth < 700 ? false : { dynamicBullets: true }}
        modules={[Autoplay, EffectFade, Navigation, Pagination]}
        className="mySwiper h-auto"
      >
      {
        offerValidity &&
        <SwiperSlide className="bg-[#ffffff]">
          <Link
            to="/shop"
            className="h-[200px] lg:h-[500px] flex items-center lg:items-start"
          >
            <img
              className="w-full h-auto z-10"
              src={banner08}
              alt="Banner 2"
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
              loading="lazy"
            />
          </Link>
        </SwiperSlide>
      }

        <SwiperSlide className="bg-[#ffffff]">
          <Link
            to="/shop"
            className="h-[200px] lg:h-[500px] flex items-center lg:items-start"
          >
            <img
              className="w-full h-auto z-10"
              src={banner06}
              alt="Banner 2"
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
              loading="lazy"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="bg-[#ffffff]">
          <Link
            to="/product-category/face%20wash"
            className="h-[200px] lg:h-[500px] flex items-center lg:items-start"
          >
            <img
              className="w-full h-auto z-10"
              src={banner02}
              alt="Banner 2"
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
              loading="lazy"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="bg-[#ffffff]">
          <Link
            to="/shop"
            className="h-[200px] lg:h-[500px] flex items-center lg:items-start"
          >
            <img
              className="w-full h-auto z-10"
              src={banner04}
              alt="Banner 7"
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
              loading="lazy"
            />
          </Link>
        </SwiperSlide>{" "}
        <SwiperSlide className="bg-[#ffffff]">
          <Link
            to="/shop"
            className="h-[200px] lg:h-[500px] flex items-center lg:items-start"
          >
            <img
              className="w-full h-auto z-10"
              src={banner01}
              alt="Banner 1"
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
              loading="eager"
            />
          </Link>
        </SwiperSlide>
        <SwiperSlide className="bg-[#ffffff]">
          <Link
            to="/product-category/shower gel"
            className="h-[200px] lg:h-[500px] flex items-center lg:items-start"
          >
            <img
              className="w-full h-auto z-10"
              src={banner07}
              alt="Banner 7"
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
              loading="lazy"
            />
          </Link>
        </SwiperSlide>
        {/* <SwiperSlide className="bg-[#ffffff]">
          <Link
            to="/product-category/hand%20wash"
            className="h-[200px] lg:h-[500px] flex items-center lg:items-start"
          >
            <img
              className="w-full h-auto z-10"
              src={banner06}
              alt="Banner 6"
              width={isDesktop ? 1920 : 400}
              height={isDesktop ? 500 : 200}
              loading="lazy"
            />
          </Link>
        </SwiperSlide> */}
      </Swiper>
    </div>
  );
};

export default Banner;
